<ng2-slim-loading-bar [ngClass]="{ 'top': pageSettings.pageEmpty }"></ng2-slim-loading-bar>
<lfx-gritter-notification *ngxPermissionsOnly="'feature_notifications_toast'"></lfx-gritter-notification>

<!-- begin #page-container -->
<div
  id="page-container"
  class="page-container fade page-sidebar-fixed page-header-fixed show"
  [ngClass]="{
    'has-scroll': pageHasScroll,
    'page-sidebar-minified': pageSettings.pageSidebarMinified,
    'page-with-footer': pageSettings.pageWithFooter,
    'page-content-full-height': pageSettings.pageContentFullHeight,
    'page-without-sidebar': pageSettings.pageWithoutSidebar,
    'page-with-right-sidebar': pageSettings.pageSidebarRight,
    'page-with-two-sidebar': pageSettings.pageSidebarTwo,
    'page-with-wide-sidebar': pageSettings.pageSidebarWide,
    'page-right-sidebar-collapsed': pageSettings.pageSidebarRightCollapsed,
    'page-with-light-sidebar': pageSettings.pageSidebarLight,
    'page-with-top-menu': pageSettings.pageTopMenu,
    'page-sidebar-toggled': pageSettings.pageMobileSidebarToggled,
    'page-right-sidebar-toggled': pageSettings.pageMobileRightSidebarToggled,
    'p-0': pageSettings.pageEmpty
  }"
>
  <lfx-top-menu
    *ngIf="pageSettings.pageTopMenu && !pageSettings.pageEmpty"
  ></lfx-top-menu>

<!--  <lfx-local-header
    (toggleSidebarRightCollapsed)="onToggleSidebarRight($event)"
    (toggleMobileSidebar)="onToggleMobileSidebar($event)"
    (toggleMobileRightSidebar)="onToggleMobileRightSidebar($event)"
    (logout)="logout($event)"
    [pageSidebarTwo]="pageSettings.pageSidebarTwo"
    [profile]="authService.userProfile$ | async"
    [notificationsLength]="notificationsLength$ | async"
    [isLoggedIn]="authService.loggedIn"
    *ngIf="!pageSettings.pageEmpty"
  ></lfx-local-header>-->

  <lfx-sidebar
    (hideMobileSidebar)="onHideMobileSidebar($event)"
    [profile]="authService.userProfile$ | async"
    *ngIf="!pageSettings.pageWithoutSidebar && !pageSettings.pageEmpty && authService.loggedIn"
    [pageSidebarTransparent]="pageSettings.pageSidebarTransparent"
    [pageSidebarMinified]="pageSettings.pageSidebarMinified"
    [isLoggedIn]="authService.loggedIn"
  ></lfx-sidebar>

  <!-- commenting this out for now, sidebar-right might be needed in future -->
<!--
  <lfx-sidebar-right
    (hideMobileRightSidebar)="onHideMobileRightSidebar($event)"
    *ngIf="pageSettings.pageSidebarTwo && !pageSettings.pageEmpty"
    iframeSrc="{{ helpUrl }}"
  ></lfx-sidebar-right> -->
  <ng-container *ngxPermissionsOnly="['feature_help']">
    <lfx-help-menu
      (getPageHelp)="pageSettings.pageHelpMenu = true"
      (createSupportTicket)="helpUrl = $event; pageSettings.pageHelpMenu = null;pageSettings.pageHelpSupportFrame = true"
      *ngIf="pageSettings.pageHelpMenu">

    </lfx-help-menu>
    <lfx-help-support-frame
      *ngIf="pageSettings.pageHelpMenu && !pageSettings.pageEmpty"
      [iframeSrc]="helpUrl"
    ></lfx-help-support-frame>
  </ng-container>

  <div
    id="content"
    class="content"
    [ngClass]="{
      'content-full-width': pageSettings.pageContentFullWidth,
      'content-inverse-mode': pageSettings.pageContentInverseMode,
      'p-0 m-0': pageSettings.pageEmpty
    }"
  >
    <router-outlet (activate)="setUserInLFxHeader()"></router-outlet>
    <ng-container  *ngxPermissionsOnly="['feature_notifications_panel']">
      <div class="notifications-container fixed-top wd-xl ml-auto">
        <router-outlet name="notifications"></router-outlet>
      </div>
    </ng-container>
    <lfx-top-btn></lfx-top-btn>
  </div>

  <lfx-footer *ngIf="pageSettings.pageWithFooter"></lfx-footer>

</div>
<!-- end #page-container -->
